.root {
    width: '100%';
    margin-top: 3;
    height: 480px;
}

.table {
    min-width: 700;
}

.empty-table {
    position: absolute;
    left: 256px;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    margin-top: 64px;
}

.modal {
    left: 0;
    right: 0;
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    top: 40%;
}