body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.toolbar_typography {
    flex-grow: 1
}
.root {
    width: '100%';
    margin-top: 3;
    height: 480px;
}

.table {
    min-width: 700;
}

.empty-table {
    position: absolute;
    left: 256px;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    margin-top: 64px;
}

.modal {
    left: 0;
    right: 0;
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    top: 40%;
}
.container{
    margin-left: 16px;
    margin-top: 8px;
}
.spinner{
    position: absolute;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    margin-top: 64px;
}
